exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-discography-index-js": () => import("./../../../src/pages/discography/index.js" /* webpackChunkName: "component---src-pages-discography-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-discography-post-js": () => import("./../../../src/templates/discography-post.js" /* webpackChunkName: "component---src-templates-discography-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-live-archive-js": () => import("./../../../src/templates/live-archive.js" /* webpackChunkName: "component---src-templates-live-archive-js" */),
  "component---src-templates-live-list-js": () => import("./../../../src/templates/live-list.js" /* webpackChunkName: "component---src-templates-live-list-js" */),
  "component---src-templates-live-post-js": () => import("./../../../src/templates/live-post.js" /* webpackChunkName: "component---src-templates-live-post-js" */),
  "component---src-templates-music-video-js": () => import("./../../../src/templates/music-video.js" /* webpackChunkName: "component---src-templates-music-video-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

